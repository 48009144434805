<div class="card border-0 bg-transparent">
    <div class="card-header bg-transparent">
        <span class="h3">
            <img src="https://img.icons8.com/color/200/000000/interstate-truck.png" class="rounded mr-2"
                style="object-fit: contain; width: 30px; height: 30px;">
            <b class="align-middle">MCMIS Search</b>
        </span>
        <div class="card-header-actions">
            <div class="input-group mt-2">
                <input type="text" id="mcmis-searchText" placeholder="Search VIN or DOT#" class="form-control"
                    value={{searchText}} [(ngModel)]="searchText">
                <span class="input-group-append">
                    <button type="button" class="btn btn-pill btn-ts btn-dark ms-2" [disabled]="!isValidSearch(searchText)"
                        (click)="search(searchText)">Search</button>
                </span>
            </div>

            <!-- <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddResource()">
                <i class="fa fa-plus mr-2"></i> Add Resource
            </button> -->
        </div>
    </div>
    <div class="card-body">
        <!-- Initial -->
        <div *ngIf="viewState == viewStates.initial" class="text-center mt-4">
            <img src="https://img.icons8.com/color/200/000000/interstate-truck.png" style="object-fit: contain;">
            <h3>MCMIS Record Search</h3>
            <p>Use the text box above to search MCMIS and related records by VIN # or DOT #</p>
        </div>

        <!-- Loading -->
        <div *ngIf="viewState == viewStates.loading" class="text-center">
            <h3>Searching records....</h3>
            <div class="spinner-border text-ts" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Results -->
        <div *ngIf="viewState == viewStates.content">

            <!--Carrier-->
            <div class="card" *ngIf="searchResponse.carrier != null">
                <div class="card-header">
                    <b>CARRIER</b>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/info--v1.png" />
                                <span class="ms-2">Basic Info</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <b>DOT Number: </b>
                                    <span>{{searchResponse.carrier.dotNumber}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Legal Name: </b>
                                    <span>{{searchResponse.carrier.legalName}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>DBA Name: </b>
                                    <span>{{searchResponse.carrier.dbaName}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Carrier Operation: </b>
                                    <span>{{searchResponse.carrier.carrierOperation}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>HM Flag: </b>
                                    <span>{{searchResponse.carrier.hmFlag}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>PC Flag: </b>
                                    <span>{{searchResponse.carrier.pcFlag}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Add Date: </b>
                                    <span>{{searchResponse.carrier.addDate}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>OIC State: </b>
                                    <span>{{searchResponse.carrier.oicState}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Power Units: </b>
                                    <span>{{searchResponse.carrier.nbrPowerUnit}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Total Drivers: </b>
                                    <span>{{searchResponse.carrier.driverTotal}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div class="col-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/building.png" />
                                <span class="ms-2">Physical Address</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <b>Street: </b>
                                    <span>{{searchResponse.carrier.phyStreet}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>City: </b>
                                    <span>{{searchResponse.carrier.phyCity}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>State: </b>
                                    <span>{{searchResponse.carrier.phyState}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Zip: </b>
                                    <span>{{searchResponse.carrier.phyZip}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Country: </b>
                                    <span>{{searchResponse.carrier.phyCountry}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div class="col-12">
                            <b>
                                <img class="align-top"
                                    src="https://img.icons8.com/color/20/000000/mailbox-closed-flag-up.png" />
                                <span class="ms-2">Mailing Address</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <b>Street: </b>
                                    <span>{{searchResponse.carrier.mailingStreet}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>City: </b>
                                    <span>{{searchResponse.carrier.mailingCity}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>State: </b>
                                    <span>{{searchResponse.carrier.mailingState}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Zip: </b>
                                    <span>{{searchResponse.carrier.mailingZip}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Country: </b>
                                    <span>{{searchResponse.carrier.mailingCountry}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <div class="col-md-12">
                            <b>
                                <img class="align-top"
                                    src="https://img.icons8.com/color/20/000000/phone-not-being-used.png" />
                                <span class="ms-2">Contact Info</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <b>Phone: </b>
                                    <span class="ms-2">{{searchResponse.carrier.telephone}}</span>
                                </div>
                                <div class="col-md-4">
                                    <b>Fax: </b>
                                    <span class="ms-2">{{searchResponse.carrier.fax}}</span>
                                </div>
                                <div class="col-md-4">
                                    <b>Email: </b>
                                    <span class="ms-2">{{searchResponse.carrier.emailAddress}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/form.png" />
                                <span class="ms-2">MCS150</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-2">
                                    <b>Date: </b>
                                    <span>{{searchResponse.carrier.mcs150Date}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Mileage: </b>
                                    <span>{{searchResponse.carrier.mcs150Mileage}}</span>
                                </div>
                                <div class="col-md-2">
                                    <b>Year: </b>
                                    <span>{{searchResponse.carrier.mcs150MileageYear}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--crashes-->
            <div class="card">
                <div class="card-header">
                    <b>CRASHES</b>
                </div>
                <div class="card-body">
                    <div *ngFor="let crash of searchResponse.crashes; let i = index">
                        <div>
                            <img class="align-top" src="https://img.icons8.com/color/20/000000/car-crash.png" />
                            <span class="ms-2">
                                <b>{{moment(crash.reportDate).format('YYYY-MM-DD')}}</b>
                                <span> | </span>
                                <span>#{{crash.reportNumber}}</span>
                            </span>
                        </div>
                        <hr class="mt-2 mb-2" />
                    </div>
                </div>
            </div>

            <!--inspections-->
            <div class="card">
                <div class="card-header">
                    <b>INSPECTIONS</b>
                </div>
                <div class="card-body">
                    <div *ngFor="let inspection of searchResponse.inspections; let i = index">
                        <div data-toggle="modal" (click)="presentInspection(inspection)" style="cursor: pointer;">
                            <img class="align-top" src="https://img.icons8.com/color/20/000000/clipboard.png" />
                            <span class="ms-2">
                                <b>{{moment(inspection.inspDate).format('YYYY-MM-DD')}}</b>
                                <span> | </span>
                                <span>#{{inspection.reportNumber}}</span>
                            </span>
                        </div>
                        <hr class="mt-2 mb-2" />
                    </div>
                </div>
            </div>

            <!--violations-->
            <div class="card">
                <div class="card-header">
                    <b>VIOLATIONS</b>
                </div>
                <div class="card-body">
                    <div *ngFor="let violation of searchResponse.violations; let i = index">
                        <div>
                            <img class="align-top" src="https://img.icons8.com/color/20/000000/high-priority.png" />
                            <span class="ms-2">
                                <b>{{moment(violation.inspDate).format('YYYY-MM-DD')}}</b>
                                <span> | </span>
                                <span>{{violation.violCode}} - {{violation.basicDesc}}</span>
                            </span>
                        </div>
                        <hr class="mt-2 mb-2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--inspection modal-->
<div bsModal #inspectionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="inspectionModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Inspection</h4>
                <button type="button" class="close" (click)="inspectionModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <div class="row">
                        <!--basic info-->
                        <div class="col-md-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/info--v1.png" />
                                <span class="ms-2">BASIC INFO</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <b>Unique ID: </b>
                                    <span>{{selectedInspection.uniqueId}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Report #: </b>
                                    <span>{{selectedInspection.reportNumber}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Report State: </b>
                                    <span>{{selectedInspection.reportState}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>DOT #: </b>
                                    <span>{{selectedInspection.dotNumber}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Insp Date: </b>
                                    <span>{{moment(selectedInspection.inspDate).format('YYYY-MM-DD')}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Insp Level ID: </b>
                                    <span>{{selectedInspection.inspLevelId}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Country Code: </b>
                                    <span>{{selectedInspection.countryCodeState}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Time Weight: </b>
                                    <span>{{selectedInspection.timeWeight}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Driver OOS: </b>
                                    <span>{{selectedInspection.driverOosTotal}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Vehicle OOS: </b>
                                    <span>{{selectedInspection.vehicleOosTotal}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <!--inspections-->
                        <div class="col-md-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/clipboard.png" />
                                <span class="ms-2">INSPECTIONS</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <b>Unsafe: </b>
                                    <span>{{selectedInspection.unsafeInsp}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Fatigued: </b>
                                    <span>{{selectedInspection.fatiguedInsp}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Dr. Fitness: </b>
                                    <span>{{selectedInspection.drFitnessInsp}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Subt Alcohol: </b>
                                    <span>{{selectedInspection.subtAlcoholInsp}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Maint: </b>
                                    <span>{{selectedInspection.vhMaintInsp}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>HM: </b>
                                    <span>{{selectedInspection.hmInsp}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <!--violations-->
                        <div class="col-md-12">
                            <b>
                                <img class="align-top" src="https://img.icons8.com/color/20/000000/high-priority.png" />
                                <span class="ms-2">VIOLATIONS</span>
                            </b>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <b>Basic: </b>
                                    <span>{{selectedInspection.basicViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Unsafe: </b>
                                    <span>{{selectedInspection.unsafeViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Fatigued: </b>
                                    <span>{{selectedInspection.fatiguedViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Dr. Fitness: </b>
                                    <span>{{selectedInspection.drFitnessViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Subt Alcohol: </b>
                                    <span>{{selectedInspection.subtAlcoholViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>Maint: </b>
                                    <span>{{selectedInspection.vhMaintViol}}</span>
                                </div>
                                <div class="col-md-3">
                                    <b>HM: </b>
                                    <span>{{selectedInspection.hmViol}}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="inspectionModal.hide()">Close</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->