import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { ViewState } from '../../../models/app';
import { McmisInspectionDto, McmisSearchResponseDto, SearchResponseDto } from '../../../models/dtos';

@Component({
    selector: 'app-mcmis-search',
    templateUrl: './search.component.html',
    styleUrls: []
})
export class McmisSearchComponent implements OnInit {

    constructor(private http: HttpClient, private titleService: Title) { }

    //vars
    searchText: string = '3120623';
    searchResponse: McmisSearchResponseDto = new McmisSearchResponseDto();
    moment: any = moment;
    selectedInspection: McmisInspectionDto = new McmisInspectionDto();

    //view states
    viewStates = ViewState;
    viewState = ViewState.initial;

    //modals
    @ViewChild('inspectionModal') public inspectionModal?: ModalDirective;


    ngOnInit() {
        this.titleService.setTitle('MCMIS Search | Trucking Standards Admin');
    }

    //api
    search(searchText: string): void {
        this.viewState = ViewState.loading;

        this.http
            .get<McmisSearchResponseDto>(`${environment.services_legacy_management}/v1/mcmis/search?q=${searchText}`)
            .subscribe(result => {
                this.searchResponse = result;
                this.viewState = ViewState.content;
            });
    }

    isValidSearch(searchText: string): Boolean {
        if (searchText == null || searchText == undefined) { return false; }

        return searchText.length > 0;
    }

    //modal
    presentInspection(inspection: McmisInspectionDto) {
        this.selectedInspection = inspection;
        this.inspectionModal?.show();
    }
}
